import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Countdown from "../components/Countdown";

import Layout from "../components/Layout";

export const IndexPageTemplate = ({ image, title, subheading }) => (
  <div
    className="home-image"
    style={{
      backgroundImage: `url(/img/teton.jpg)`,
    }}
  >
    <div
      className="container"
      style={{
        height: "100%",
      }}
    >
      <div className="home-image-copy">
        <h1>{title}</h1>
        <h2>{subheading}</h2>
        <h2>
          <Countdown />
        </h2>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout isHomePage>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
      }
    }
  }
`;
