import React from "react";
import moment from "moment";

import { WEDDING_DATE } from "../constants";

class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysUntil: this.getDaysUntil(),
    };
  }

  getDaysUntil() {
    return WEDDING_DATE.diff(moment(), "days");
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ daysUntil: this.getDaysUntil() });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <span>{this.state.daysUntil} days to go!</span>;
  }
}

export default Countdown;
